import * as React from "react"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"

import { useForm, SubmitHandler } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { Box, Container, Grid, Paper, TextField } from "@mui/material"
import { Layout } from "../components/layout"
import { useState } from "react"

type UserSubmitForm = {
  name: string
  email: string
  phone: string
  message: string
}

const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

export const ContactUs: React.FC = () => {
  const personSchema = Yup.object().shape({
    name: Yup.string().required("Fullname is required"),
    email: Yup.string()
      .required("Email is required")
      .matches(emailRegex, "Invalid email"),
    message: Yup.string().required("Message is required"),
  })

  const [submitted, setSubmitted] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(personSchema),
  })

  const onSubmit: SubmitHandler<UserSubmitForm> = data => {
    fetch("/.netlify/functions/send-email", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "form-name": "contact",
        ...data,
      }),
    })
      .then(() => showSuccess())
      .catch(error => showError(error))

    console.log(data)
  }

  const showSuccess = () => {
    setSubmitted(true)
    console.log(`form submitted successfully`)
    reset()
  }

  const showError = (error: any) => {
    setSubmitted(false)
    console.log(`There was an error submitting the form`)
    console.log(error)
  }

  return (
    <Layout>
      <Paper
        sx={{
          position: "relative",
          backgroundColor: "grey.800",
          color: "#fff",
          mb: 4,
          height: "30vh",
          overflow: "hidden",
          borderRadius: 0,
        }}
      >
        {/* <GatsbyImage fluid={img} alt={imgAlt || title} /> */}
        <img src="/contact.jpeg" alt="" />
        <Grid
          container
          sx={{
            inset: 0,
            backgroundColor: "rgba(0,0,0, .7)",
            position: "absolute",
            zIndex: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Container>
            <Grid item>
              <Box>
                <Typography
                  component="h1"
                  variant="h4"
                  color="inherit"
                  gutterBottom
                >
                  Get in touch with our team
                </Typography>
                <Typography
                  color="inherit"                                    
                  paragraph
                  sx={{ display: "flex" }}
                >
                  Drop us a line, and our rep will contact you as soon as
                  possible to arrange an initial discussion.
                </Typography>
              </Box>
            </Grid>
          </Container>
        </Grid>
      </Paper>
      <Container>
        <Grid
          container
          spacing={2}
          
          
        >
          <Grid item xs={12}>
            {submitted && (
              <Grid my={3}>
                <Typography gutterBottom variant="h4">
                  Thanks for submitting!
                </Typography>
                <Typography gutterBottom variant="body1">
                  &nbsp;We will reach out ASAP!
                </Typography>
              </Grid>
            )}

            {!submitted && (
              <form
                onSubmit={handleSubmit(onSubmit)}
                name="contact"
                method="POST"
              >
                <Grid
                  container
                  direction="column"
                  spacing={2}
                  flexGrow={1}
                  display="flex"
                >
                  <Grid item>
                    <TextField
                      id="name"
                      label="Full Name"
                      {...register("name")}
                      helperText={errors.name ? errors.name.message : ""}
                      error={Boolean(errors.name)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="email"
                      label="Email Address"
                      {...register("email")}
                      helperText={errors.email ? errors.email.message : ""}
                      error={Boolean(errors.email)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="phone"
                      label="Phone"
                      {...register("phone")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="message"
                      label="Message"
                      multiline
                      rows={4}
                      {...register("message")}
                      helperText={errors.message ? errors.message.message : ""}
                      error={Boolean(errors.message)}
                      fullWidth
                    />
                  </Grid>

                  <Grid item justifyContent="flex-end" display="flex">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={Object.keys(errors).length !== 0}
                      sx={{
                        backgroundColor:
                          "linear-gradient(to right, #6372ff 50%, #5ca9fb 100%)",

                        outline: "none",
                        "&:active": {
                          outline: "none",
                        },
                        "&:focus": {
                          outline: "none",
                        },
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>{" "}
              </form>
            )}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default ContactUs
